<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <div class="card bg-dark p-3">
          <h3 class="font-weight-bolder text-white">
            <i class="fa fa-cog" /> Customer Setting
          </h3>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="bg-primary p-1 text-white rounded-top">
            ตั้งค่าหน้าแสดงผล
          </div>

          <div class="p-1">
            <Logo :logo="form.logo" @setData="setLogo" />
            <BackgroundColor :bg="form.bg_color" @setData="setBGColor" />
            <Background :bg="form.bg" @setData="setBG" />
            <Slides
              :slides="form.slides"
              @setData="setSlides"
              @removeData="removeSlide"
            />
            <NavColor :color="form.nav_color" @setData="setNavColor" />
            <NavButtonColor
              :color="form.nav_button_color"
              @setData="setNavButtonColor"
            />
            <DrawerColor :color="form.drawer_color" @setData="setDrawerColor" />
            <DrawerButtonColor
              :color="form.drawer_button_color"
              @setData="setDrawerButtonColor"
            />
            <GameColor :color="form.game_color" @setData="setGameColor" />
            <GameButtonColor
              :color="form.game_button_color"
              @setData="setGameButtonColor"
            />
            <AnnounceColor
              :color="form.announce_color"
              @setData="setAnnounceColor"
            />
            <AnnounceTextColor
              :color="form.announce_text_color"
              @setData="setAnnounceTextColor"
            />
            <TitleColor :color="form.title_color" @setData="setTitleColor" />

            <hr class="mt-2" />
            <div class="d-flex">
              <button class="btn btn-success mr-1" @click="saveAllSetting()">
                บันทึกการตั้งค่า
              </button>

              <button
                class="btn btn-outline-primary"
                @click="resetAllSetting()"
              >
                ค่าเริ่มต้น
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <Preview :data="form" />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ColorPicker from "vue2-color-picker-gradient";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg, BOverlay, BIconController } from "bootstrap-vue";
import "swiper/css/swiper.css";

import Logo from "./customer-settings/Logo.vue";
import Background from "./customer-settings/Background.vue";
import BackgroundColor from "./customer-settings/BackgroundColor.vue";
import Slides from "./customer-settings/Slides.vue";
import NavColor from "./customer-settings/NavColor.vue";
import NavButtonColor from "./customer-settings/NavButtonColor.vue";
import DrawerColor from "./customer-settings/DrawerColor.vue";
import DrawerButtonColor from "./customer-settings/DrawerButtonColor.vue";
import GameColor from "./customer-settings/GameColor.vue";
import GameButtonColor from "./customer-settings/GameButtonColor.vue";
import AnnounceColor from "./customer-settings/AnnounceColor.vue";
import AnnounceTextColor from "./customer-settings/AnnounceTextColor.vue";
import TitleColor from "./customer-settings/TitleColor.vue";
import Preview from "./customer-settings/Preview.vue";
import axios from "axios";

export default {
  name: "CustomerSetting",
  components: {
    // BFormFile,
    ColorPicker,
    Swiper,
    SwiperSlide,
    BImg,
    BIconController,
    BOverlay,
    Logo,
    Background,
    BackgroundColor,
    Slides,
    NavColor,
    NavButtonColor,
    DrawerColor,
    DrawerButtonColor,
    GameColor,
    GameButtonColor,
    AnnounceColor,
    AnnounceTextColor,
    TitleColor,
    Preview,
  },
  data() {
    return {
      isLoading: false,
      form: {
        agent_id: null,
        logo: null,
        bg: null,
        bg_color: null,
        slides: [],
        nav_color: null,
        nav_button_color: null,
        drawer_color: null,
        drawer_button_color: null,
        game_color: null,
        game_button_color: null,
        announce_color: null,
        announce_text_color: null,
        title_color: null,
      },
      // api_endpoint: window.location.origin === 'http://localhost:8080' ? 'http://localhost:3344/api' :
    };
  },
  mounted() {
    // this.getAgent();
    this.getSetting();
  },
  methods: {
    // async getAgent() {
    //   this.isLoading = true;
    //   // const endpoint = localStorage.getItem('endpoint')
    //   try {
    //     const { data } = await axios.post(
    //       "http://localhost:3344/api/agent/showid",
    //       {
    //         site_admin: "https://admin.power77th.com",
    //       }
    //     );
    //     console.log(data);
    //     this.form.agent_id = data.id;
    //     // this.imagePreviewUrl = data.logo_path;
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     this.isLoading = false
    //   }
    // },
    async getSetting() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get("/agent/setting");

        this.form.logo = data.logo;
        this.form.slides = data.slides ? data.slides : [];
        this.form.bg_color = data.bg_color;
        this.form.bg = data.bg;
        this.form.nav_color = data.nav_color;
        this.form.nav_button_color = data.nav_button_color;
        this.form.drawer_color = data.drawer_color;
        this.form.drawer_button_color = data.drawer_button_color;
        this.form.game_color = data.game_color;
        this.form.game_button_color = data.game_button_color;
        this.form.announce_color = data.announce_color;
        this.form.announce_text_color = data.announce_text_color;
        this.form.title_color = data.title_color;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async saveAllSetting() {
      const { value: accept } = await this.$swal({
        title: "บันทึกการตั้งค่า",
        text: "คุณต้องการบันทึกการตั้งค่าหรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึกข้อมูล",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "swal-confirm-button",
        },
      });

      if (accept) {
        this.isLoading = true;
        // console.log(this.form)
        try {
          const { data } = await this.$http.post("/agent/setting", this.form);

          console.log(data);
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async resetAllSetting() {
      const { value: accept } = await this.$swal({
        title: "ล้างการตั้งค่า",
        text: "คุณต้องการล้างการตั้งค่าหรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ล้างข้อมูล",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "swal-confirm-button",
        },
      });

      if (accept) {
        this.form = {
          agent_id: null,
          logo: null,
          bg: null,
          bg_color: null,
          slides: [],
          nav_color: null,
          nav_button_color: null,
          drawer_color: null,
          drawer_button_color: null,
          game_color: null,
          game_button_color: null,
          announce_color: null,
          announce_text_color: null,
          title_color: null,
        };

        this.isLoading = true;
        try {
          const { data } = await this.$http.post("/agent/setting", this.form);

          console.log(data);
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      }
    },
    setLogo(e) {
      this.form.logo = e;
    },
    setBG(e) {
      this.form.bg = e;
    },
    async setSlides(e) {
      e.forEach(async (item) => {
        // console.log(await item);
        this.form.slides.push(await item);
      });
      // await e.forEach(item => {
      //   console.log(item)
      // })
      // this.form.slides = e;
    },
    removeSlide(e) {
      this.form.slides.splice(e, 1);
    },
    setBGColor(e) {
      this.form.bg_color = e;
    },
    setNavColor(e) {
      this.form.nav_color = e;
    },
    setNavButtonColor(e) {
      this.form.nav_button_color = e;
    },
    setDrawerColor(e) {
      this.form.drawer_color = e;
    },
    setDrawerButtonColor(e) {
      this.form.drawer_button_color = e;
    },
    setGameColor(e) {
      this.form.game_color = e;
    },
    setGameButtonColor(e) {
      this.form.game_button_color = e;
    },
    setAnnounceColor(e) {
      this.form.announce_color = e;
    },
    setAnnounceTextColor(e) {
      this.form.announce_text_color = e;
    },
    setTitleColor(e) {
      this.form.title_color = e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
