<template>
  <div>
    <div class="setting-colr rounded mb-1" @click="show = !show">
      <p class="mb-0">พื้นหลังประกาศ</p>

      <i class="fas fa-plus-circle" />
    </div>

    <div v-if="show" class="clrr-setting">
      <div class="color_poick">
        <ColorPicker
          :pColor="color || pColor"
          :title-config="titleGradient"
          :close-on-click-body="false"
          @changeColor="changeColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "vue2-color-picker-gradient";
export default {
  components: {
    ColorPicker,
  },
  props: ["color"],
  data: () => ({
    show: false,
    titleGradient: {
      show: true,
      text: "เลือกสีพื้นหลังประกาศ",
    },
    pColor: {
      hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
      hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
      rgba: { r: 25, g: 77, b: 51, a: 1 },
      a: 1,
      color: "rgba(0,0,0,1)",
    },
  }),
  mounted() {},
  methods: {
    async changeColor(event) {
      this.$emit("setData", event.color);
    },
  },
};
</script>
